export default class FeedbackModal {

  constructor(apiKey) {
    if (!apiKey) {
      console.log('Feedback Modal Error - No API Key');
      return;
    }
    this._apiKey = apiKey;
    this._propertyName = 'us';
    this._apiURL = 'https://www.machinecore.com/api/feedback';
    this._stickyButtonColor = '#962222';
    this._delayAutoPopUpTime = -1;
    this._initSticky();
    // this._apiURL = 'http://0.0.0.0:5000/api/feedback';
  }

  set propertyName(val) {
    this._propertyName = val;
  }

  set stickyButtonColor(val) {
    this._stickyButtonColor = val;
    this._setStickyButtonColor();
  }

  set delayAutoPopUpTime(val) {
    if (Number.isInteger(val) && val >= 0) {
      this._delayAutoPopUpTime = val;
      this._runDelayAutoPopUpTime();
    }
  }

  hideStickyButton() {
    this._destroyStickyButton();
  }

  showStickyButton() {
    this._initSticky();
  }

  open() {
    if (!this._apiKey) {
      console.log('Feedback Modal Error - No API Key Unable to Open Modal');
      return;
    }
    this._init();

    if (this.modal.style.removeProperty) {
      this.modal.style.removeProperty('display');
    } else {
      this.modal.style.removeAttribute('display');
    }
    if (document.getElementById('feedback-text')) {
      if (!document.getElementById('feedback-text').value) {
        this._clearFormData();
      }
    }

    // prevent double scroll
    this._scrollPosition = window.pageYOffset;
    document.body.classList.add('feedback-enabled');
    document.body.style.top = -this._scrollPosition + 'px';
    this._toggleErrorMessage(false);

    // show modal
    this.modal.classList.add('feedback-modal--visible');
  }

  close() {
    this._destroy();
    // document.body.classList.remove('feedback-enabled');
    window.scrollTo(0, this._scrollPosition);
    document.body.style.top = null;
    // this.modal.classList.remove('feedback-modal--visible');
  }

  _destroy() {
    if (this.modal === null) {
      return;
    }

    // unbind all events
    this._unbindEvents.call(this);
    document.body.classList.remove('feedback-enabled');
    // remove modal from dom
    this.modal.parentNode.removeChild(this.modal);

    this.modal = null;
  };

  _initSticky() {
    if (this.modalSticky) {
      return;
    }
    this._buildSticky.call(this);
    this._bindEventsSticky.call(this);
    document.body.appendChild(this.modalSticky);
  }

  _buildSticky() {
    // alert('build function called');
    this.modalSticky = document.createElement('div');
    this.modalSticky.classList.add('feedback-sticky');
    this._setStickyButtonColor();
    // modal box content
    /* eslint-disable */
    this.modalSticky.innerHTML += '<a href="#">Feedback '
    this.modalSticky.innerHTML += '<img src="https://static.machinecore.com/feedback-icon@2x.png" srcset="https://static.machinecore.com/feedback-icon@1x.png 1x, https://static.machinecore.com/feedback-icon@2x.png 2x" /></a>';
    /* eslint-enable */
  }

  _setStickyButtonColor() {
    if (this.modalSticky) {
      this.modalSticky.style.backgroundColor = this._stickyButtonColor;
    }
  }

  _setStickyButtonColor() {
    if (this.modalSticky) {
      this.modalSticky.style.backgroundColor = this._stickyButtonColor;
    }
  }

  _runDelayAutoPopUpTime() {
    if (this._delayAutoPopUpTime > 0) {
      setTimeout(() => { this.open(); }, this._delayAutoPopUpTime * 1000);
    } else {
      this.open();
    }
  }

  _bindEventsSticky() {
    this.modalSticky.addEventListener('click', this.open.bind(this));
  }

  _destroyStickyButton() {
    if (!this.modalSticky) {
      return;
    }

    this.modalSticky.removeEventListener('click', this.open.bind(this));
    this.modalSticky.parentNode.removeChild(this.modalSticky);
    this.modalSticky = null;
  }

  _init() {
    if (this.modal) {
      return;
    }
    this._build.call(this);
    this._bindEvents.call(this);
    document.body.insertBefore(this.modal, document.body.firstChild);
    this._buildFooter.call(this);
    this._eventsFeedbackRatingSelection.call(this);
    this._addButtons.call(this);
  }

  _isOpen() {
    return !!this.modal.classList.contains('feedback-modal--visible');
  };

  _checkOverflow() {
    // only if the modal is currently shown
    if (this.modal.classList.contains('feedback-modal--visible')) {
      if (this._isOverflow()) {
        this.modal.classList.add('feedback-modal--overflow');
      } else {
        this.modal.classList.remove('feedback-modal--overflow');
      }
    }
  }

  _isOverflow() {
    let viewportHeight = window.innerHeight;
    let modalHeight = this.modalBox.clientHeight;

    return modalHeight >= viewportHeight;
  };

  _makeRequest(method, url, payload, apiKey) {
    return new Promise(function (resolve, reject) {
      let xhr = new XMLHttpRequest();

      xhr.open(method, url);
      xhr.onload = function () {
        if (this.status >= 200 && this.status < 300) {
          resolve(xhr.response);
        } else {
          reject({
            status: this.status,
            statusText: xhr.statusText
          });
        }
      };
      xhr.onerror = function () {
        reject({
          status: this.status,
          statusText: xhr.statusText
        });
      };
      xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.setRequestHeader('x-api-key', apiKey);
      xhr.send(JSON.stringify(payload));
    });
  }

  _clearFormData() {
    this._resetFeedbackRatingSelection();
    document.getElementById('feedback-text').value = '';
    document.getElementById('happy').checked = false;
    document.getElementById('neutral').checked = false;
    document.getElementById('mad').checked = false;
    document.getElementById('referChoiceYes').checked = false;
    document.getElementById('referChoiceNo').checked = false;
  }

  _getFormData() {
    let feedbackText = document.getElementById('feedback-text').value;

    let emotion = 5;

    if (document.getElementById('happy').checked) {
      emotion = 10;
    } else if (document.getElementById('mad').checked) {
      emotion = 1;
    }

    let referRating = 1;

    if (document.getElementById('referChoiceYes').checked) {
      referRating = 10;
    }
/* eslint-disable */
    let payload = {
      feedback: feedbackText,
      contact: '',
      page_url: window.location.href,
      screen_resolution: screen.width + 'x' + screen.height,
      feedback_rating: emotion,
      referral_rating: referRating,
      page_referral_url: document.referrer,
      page_title: document.title,
      user_agent: window.navigator.userAgent
    };
/* eslint enable */ 
    return payload;
  }

  _checkFormData() {
    let error = false;

    if (document.getElementById('feedback-text').value === '') {
      error = true;
    }
    if (!document.getElementById('happy').checked &&
      !document.getElementById('neutral').checked &&
      !document.getElementById('mad').checked) {
      error = true;
    }
    if (!document.getElementById('referChoiceYes').checked &&
      !document.getElementById('referChoiceNo').checked) {
      error = true;
    }

    return !error;
  }

  _apiPostData() {
    let self = this;

    if (this._checkFormData()) {
      this._makeRequest('POST', this._apiURL, this._getFormData(), this._apiKey)
        .then((datums) => {
          console.log(datums);
          self._clearFormData();
          this.modalBoxContent.innerHTML = `
            <div id="feedback">
                <h3>Thanks for your feedback!</h3>
            </div>
          `;
          this._unbindButtonEvents();
          this.modalBoxFooter.innerHTML = '';
          setTimeout(() => {
            self.close();
          }, 3000);
          //
        })
        .catch((err) => {
          console.error('Augh, there was an error!', err.status);
        });
    } else {
      this._toggleErrorMessage(true);
    }
  }

  _toggleErrorMessage(state) {
    let modalBoxError = document.getElementById('feedback-error');

    if (state) {
      modalBoxError.style.display = 'block';
    } else {
      modalBoxError.style.display = 'none';
    }
  }

  _eventsFeedbackRatingSelection() {
    let parent = document.getElementById('feedback-emotion');
    let emotions = parent.getElementsByTagName('label');
    let happy = emotions[0];

    let self = this;

    happy.addEventListener('click',
      this._changeFeedbackRatingSelection.bind(self));

    let neutral = emotions[1];

    neutral.addEventListener('click',
      this._changeFeedbackRatingSelection.bind(self));

    let sad = emotions[2];

    sad.addEventListener('click',
      this._changeFeedbackRatingSelection.bind(self));
  }

  _resetFeedbackRatingSelection() {
    let parent = document.getElementById('feedback-emotion');
    let emotions = parent.getElementsByTagName('label');

    for (let i = 0; i < emotions.length; i++) {
      let imgElement = emotions[i].getElementsByTagName('img');

      // remove selected class from image
      if (imgElement[0].classList.length > 0) {
        imgElement[0].classList.remove('selected');
      }
    }
  }

  _changeFeedbackRatingSelection(event) {
    this._resetFeedbackRatingSelection();

    let target = event.target;

    target.classList.add('selected');
  }

  _addButtons() {
    this._addFooterButton('SUBMIT FEEDBACK',
      'feedback-btn feedback-btn--primary',
      this._apiPostData.bind(this));
    this._addFooterButton('CLOSE',
      'feedback-btn feedback-btn--danger',
      this.close.bind(this));
  }

  _build() {
    // alert('build function called');
    this.modal = document.createElement('div');
    this.modal.classList.add('feedback-modal');

    this.modal.style.display = 'none';

    this.modalCloseBtn = document.createElement('button');
    this.modalCloseBtn.classList.add('feedback-modal__close');

    this.modalCloseBtnIcon = document.createElement('span');
    this.modalCloseBtnIcon.classList.add('feedback-modal__closeIcon');
    this.modalCloseBtnIcon.innerHTML = '×';

    this.modalCloseBtnLabel = document.createElement('span');
    this.modalCloseBtnLabel.classList.add('feedback-modal__closeLabel');
    this.modalCloseBtnLabel.innerHTML = 'Close';

    this.modalCloseBtn.appendChild(this.modalCloseBtnIcon);
    this.modalCloseBtn.appendChild(this.modalCloseBtnLabel);

    // modal
    this.modalBox = document.createElement('div');
    this.modalBox.classList.add('feedback-modal-box');

    // modal box content
    this.modalBoxContent = document.createElement('div');
    this.modalBoxContent.classList.add('feedback-modal-box__content');
    this.modalBoxContent.innerHTML = `
        <div id="feedback">
          <h2>Give Us Feedback</h2>
          <form>
            <span id="feedback-error">Please fill out entire form</span>
            <p>What do you want to tell us?</p>
            <textarea id="feedback-text" rows="10"></textarea><br>
            <p>How does your feedback make you feel?</p>
            <div id="feedback-emotion" class="feedback-emoji">
              <input type="radio" class="input_hidden" name="emotion" id="happy" />
              <label for="happy">
                <img src="https://static.machinecore.com/happy.png" alt="happy" />
              </label>
              <input type="radio" class="input_hidden" name="emotion" id="neutral" />
              <label for="neutral">
                <img src="https://static.machinecore.com/neutral.png" alt="neutral" />
              </label>
              <input type="radio" class="input_hidden" name="emotion" id="mad" />
              <label for="mad">
                <img src="https://static.machinecore.com/mad.png" alt="mad" />
              </label>
            </div>
            <p>Would you recommend ${this._propertyName} to a friend?</p>
            <div class="feedback-referral">
              <input type="radio" class="feedback-inline" id="referChoiceYes" name="refer" value="1">
              <label class="feedback-refer feedback-inline" for="referChoiceYes">Yes</label>&nbsp;&nbsp;
              <input type="radio" class="feedback-inline feedback-padding" id="referChoiceNo" name="refer" value="0">
              <label class="feedback-refer feedback-inline" for="referChoiceNo">No</label>
            </div>
          </form>
        </div>`;

    this.modalBox.appendChild(this.modalBoxContent);
    this.modal.appendChild(this.modalCloseBtn);
    this.modal.appendChild(this.modalBox);
  }

  _buildFooter() {
    this.modalBoxFooter = document.createElement('div');
    this.modalBoxFooter.classList.add('feedback-modal-box__footer');
    this.modalBox.appendChild(this.modalBoxFooter);
  }

  _addFooterButton(label, cssClass, callback) {
    let btn = document.createElement('button');

    // set label
    btn.innerHTML = label;

    // bind callback
    btn.addEventListener('click', callback);

    if (typeof cssClass === 'string' && cssClass.length) {
      // add classes to btn
      cssClass.split(' ').forEach(function (item) {
        btn.classList.add(item);
      });
    }

    this.modalBoxFooter.appendChild(btn);

    return btn;
  }

  _handleKeyboardNav(event) {
    // escape key
    if (event.which === 27 && this.isOpen()) {
      this.close();
    }
  }

  _handleClickOutside(event) {
    // if click is outside the modal
    if (!this._findAncestor(event.target, 'feedback-modal') &&
      event.clientX < this.modal.clientWidth) {
      this.close();
    }
  }

  _findAncestor(el, cls) {
    while ((el = el.parentElement) && !el.classList.contains(cls));
    return el;
  }

  _bindEvents() {
    this._events = {
      clickCloseBtn: this.close.bind(this),
      clickOverlay: this._handleClickOutside.bind(this),
      resize: this._checkOverflow.bind(this),
      keyboardNav: this._handleKeyboardNav.bind(this)
    };

    this.modalCloseBtn.addEventListener('click', this._events.clickCloseBtn);
    this.modal.addEventListener('mousedown', this._events.clickOverlay);
    window.addEventListener('resize', this._events.resize);
    document.addEventListener('keydown', this._events.keyboardNav);
  }

  _unbindEvents() {
    this.modalCloseBtn.removeEventListener('click', this._events.clickCloseBtn);
    this.modal.removeEventListener('mousedown', this._events.clickOverlay);
    window.removeEventListener('resize', this._events.resize);
    document.removeEventListener('keydown', this._events.keyboardNav);
  }

  _unbindButtonEvents() {
    this.modalBoxFooter.getElementsByTagName('button')[0].removeEventListener('click', this._apiPostData.bind(this));
    this.modalBoxFooter.getElementsByTagName('button')[1].removeEventListener('click', this.close.bind(this));
  }
}
